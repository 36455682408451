import { signUp } from "aws-amplify/auth";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MIN_PASSWORD_LENGTH } from "shared/config/cognito";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { cognitoPaths } from "./paths";
import { Form } from "../../../../components/form/form";
import { FormTextInput } from "../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../components/form/loading-button";
import { TranslationFeKey, tx } from "../../../../components/text";
import { userHasInteractedWithSignupForm } from "../../../../util/data-layer-actions";
import { isEmailRegex } from "../../../../util/regex";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";
import { returnLocalizedCognitoError } from "../misc/cognito-errors";

export const CognitoSignUp: React.FC<{
  userShouldRepeatEmail?: boolean;
}> = ({ userShouldRepeatEmail = false }) => {
  const { hash } = useLocation();

  const { prefillValues } = useAuthNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
    password: string;
    confirmPassword: string;
    groupSecret?: string;
    confirmEmail?: string;
  }>({ defaultValues: prefillValues });
  const navigate = useAuthNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );

  useEffect(() => {
    if (hash !== "#override") {
      navigate({
        to: {
          type: "uri",
          uri: cognitoPaths.signUpDeprecated,
        },
        replace: true,
      });
    }
  }, [hash, navigate]);

  useEffect(() => {
    if (isDirty) {
      userHasInteractedWithSignupForm();
    }
  }, [isDirty]);

  const {
    i18n: { language },
  } = useTranslation();
  const { disease, organisation } = useTenantId();

  const onSubmitTrySignUp = handleSubmit(
    ({ email, name, password, groupSecret }) => {
      const clientMetadata: CognitoClientMetadata & { [key: string]: string } =
        {
          disease,
          ...(organisation ? { organisation } : {}),
          isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
          language,
          ...(groupSecret ? { groupSecret } : {}),
        };
      return signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            name,
            email,
          },
          autoSignIn: false,
          clientMetadata,
        },
      })
        .then(({ isSignUpComplete }) => {
          if (isSignUpComplete) {
            navigate({
              to: {
                type: "uri",
                uri: cognitoPaths.signIn,
              },
              state: {
                message: {
                  level: AuthMessageLevel.INFO,
                  tx: "auth.signUp.autoConfirmedRegistrationComplete",
                },
              },
              replace: true,
            });
          } else {
            navigate({
              to: { type: "uri", uri: cognitoPaths.confirmSignUpInfo },
              replace: true,
            });
          }
        })
        .catch((err) => {
          navigate({
            to: {
              type: "current_url",
            },
            replace: true,
            state: {
              message: {
                level: AuthMessageLevel.ERROR,
                ...returnLocalizedCognitoError(err.code, err.message),
              },
            },
          });
        });
    },
  );

  return (
    <Form onSubmit={onSubmitTrySignUp}>
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.name.title" }}
        placeholder={{ tx: "auth.signUp.inputs.name.placeholder" }}
        {...register("name", {
          required: tx("auth.signUp.inputs.name.fieldMissingError"),
          validate: (value) => {
            if (value.trim() === "") {
              return tx("auth.signUp.inputs.name.fieldMissingError");
            }
          },
        })}
        error={{ txUnchecked: errors.name?.message }}
      />
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.email.title" }}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
      {userShouldRepeatEmail ? (
        <FormTextInput
          title={{ tx: "auth.signUp.inputs.confirmEmail.title" }}
          placeholder={{ tx: "auth.signUp.inputs.confirmEmail.placeholder" }}
          autoComplete="off"
          {...register("confirmEmail", {
            required: tx("auth.signUp.inputs.confirmEmail.fieldMissingError"),
            validate: (
              val: string | undefined,
            ): TranslationFeKey | undefined => {
              if (getValues("email") !== val) {
                return "auth.signUp.inputs.confirmEmail.emailsDoNotMatchError";
              }
            },
          })}
          error={{ txUnchecked: errors.confirmEmail?.message }}
        />
      ) : null}
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.password.title" }}
        placeholder={{ tx: "auth.signUp.inputs.password.placeholder" }}
        type="password"
        autoComplete="new-password"
        {...register("password", {
          required: tx("auth.signUp.inputs.password.fieldMissingError"),
          minLength: {
            value: MIN_PASSWORD_LENGTH,
            message: tx("auth.signUp.inputs.password.passwordTooShortError"),
          },
        })}
        error={{
          txUnchecked: errors.password?.message,
          txData: { min_password_length: MIN_PASSWORD_LENGTH },
        }}
      />
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.confirmPassword.title" }}
        placeholder={{ tx: "auth.signUp.inputs.confirmPassword.placeholder" }}
        type="password"
        autoComplete="new-password"
        {...register("confirmPassword", {
          required: tx("auth.signUp.inputs.confirmPassword.fieldMissingError"),
          validate: (val: string): TranslationFeKey | undefined => {
            if (getValues("password") !== val) {
              return "auth.signUp.inputs.confirmPassword.passwordsDoNotMatchError";
            }
          },
        })}
        error={{ txUnchecked: errors.confirmPassword?.message }}
      />

      <LoadingButton
        type="submit"
        loading={isSubmitting}
        tx="auth.signUp.signUpButton"
      />
    </Form>
  );
};
