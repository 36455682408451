declare global {
  interface Window {
    dataLayer: { event: string; user_id?: string }[];
  }
}

export const userHasStartedSignup = (): void => {
  if (!doesEventExist("signup_started")) {
    window.dataLayer.push({ event: "signup_started" });
  }
};

export const userHasCreatedAccount = (): void => {
  if (!doesEventExist("account_created")) {
    window.dataLayer.push({ event: "account_created" });
  }
};

export const userHasConfirmedAccount = (): void => {
  if (!doesEventExist("account_confirmed")) {
    window.dataLayer.push({ event: "account_confirmed" });
  }
};

export const userHasFinishedSignup = (): void => {
  if (!doesEventExist("signup_finished")) {
    window.dataLayer.push({ event: "signup_finished" });
  }
};

export const userHasInteractedWithSignupForm = (): void => {
  if (!doesEventExist("signup_form_touched")) {
    window.dataLayer.push({ event: "signup_form_touched" });
  }
};

export const identifyUserBasedOnId = (id: string): void => {
  if (!doesEventExist("user_id")) {
    window.dataLayer.push({
      event: "user_id",
      user_id: id,
    });
  }
};

const doesEventExist = (event: string) => {
  return window.dataLayer?.some((dl) => dl.event === event);
};
