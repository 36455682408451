import { confirmSignIn, signIn, signUp } from "aws-amplify/auth";
import { StringMap } from "shared/types/cognito-client-metadata";

import {
  generatePassword,
  SignInResponse,
  SignInStatus,
  SignUpResponse,
  SignUpStatus,
} from "../routes/cognito-magic-link-sign-up";

export interface CognitoAuthInput {
  email: string;
  name: string;
  clientMetadata: StringMap;
}

function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export const signUserIn = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignInResponse> => {
  try {
    const username = email;
    const { isSignedIn, nextStep } = await signIn({
      username,
      options: {
        authFlowType: "CUSTOM_WITHOUT_SRP",
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (isSignedIn) {
      return {
        status: SignInStatus.UserAlreadySignedIn,
        error: new Error(
          `Sign in step returned already signed in: ${isSignedIn}`,
        ),
      };
    }

    if (nextStep.signInStep !== "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
      return {
        status: SignInStatus.UserChallangeInvalid,
        error: new Error(
          `Sign in step returned invalid sign in step: ${nextStep.signInStep}`,
        ),
      };
    }

    const { isSignedIn: confirmSignedIn, nextStep: confirmNextStep } =
      await confirmSignIn({
        challengeResponse: "__dummy__",
        options: { clientMetadata },
      });

    if (confirmSignedIn) {
      return {
        status: SignInStatus.UserConfirmedSignedIn,
        error: new Error(
          `Confirmation step returned already signed in: ${confirmSignedIn}`,
        ),
      };
    }

    if (
      confirmNextStep.signInStep !== "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE"
    ) {
      return {
        status: SignInStatus.UserConfirmedChallangeInvalid,
        error: new Error(
          `Confirmation step returned invalid sign in step: ${confirmNextStep.signInStep}`,
        ),
      };
    }

    return { status: SignInStatus.MagicLinkSent };
  } catch (err) {
    if (err instanceof Error) {
      if (err.name === "UserNotFoundException") {
        return { status: SignInStatus.UserNotFound, error: err };
      }
    }

    return {
      status: SignInStatus.UnknownError,
      error: new Error(
        `Got unexpected error from sign in step: ${getErrorMessage(err)}`,
      ),
    };
  }
};

export const signUserUp = async ({
  email,
  name,
  clientMetadata,
}: CognitoAuthInput): Promise<SignUpResponse> => {
  const password = generatePassword();

  try {
    const { isSignUpComplete, nextStep, userId } = await signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          name,
          email,
        },
        autoSignIn: false,
        clientMetadata,
      },
    });

    if (!isSignUpComplete || nextStep.signUpStep !== "DONE") {
      return {
        status: SignUpStatus.SignUpNotDone,
        error: new Error(
          "Unexpected state (" +
            isSignUpComplete +
            ", " +
            nextStep.signUpStep +
            ") after sign up",
        ),
      };
    }

    if (!userId) {
      return {
        status: SignUpStatus.SignUpNotDone,
        error: new Error("User ID is missing after sign up"),
      };
    }

    const signInStatus = await signUserIn({
      email,
      name,
      clientMetadata,
    });

    if (signInStatus.status === SignInStatus.MagicLinkSent) {
      return { status: SignUpStatus.MagicLinkSent };
    }

    return {
      status: SignUpStatus.UnknownError,
      error: new Error(`Error from sign in: ${signInStatus.error}`),
    };
  } catch (err) {
    return {
      status: SignUpStatus.UnknownError,
      error: new Error(
        `Got unexpected error from sign up step: ${getErrorMessage(err)}`,
      ),
    };
  }
};
