import * as Sentry from "@sentry/react";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CognitoClientMetadata } from "shared/types/cognito-client-metadata";

import { cognitoPaths } from "./paths";
import { Form } from "../../../../components/form/form";
import { FormTextInput } from "../../../../components/form/form-text-input";
import { LoadingButton } from "../../../../components/form/loading-button";
import { TranslationFeKey, tx } from "../../../../components/text";
import { userHasInteractedWithSignupForm } from "../../../../util/data-layer-actions";
import { isEmailRegex } from "../../../../util/regex";
import { useTenantId } from "../../../../util/use-active-tenant-id";
import { AuthMessageLevel } from "../../generic/types";
import { useAuthNavigate } from "../../generic/use-auth-navigate";
import { useAuthNavigateState } from "../../generic/use-auth-navigate-state";
import { signUserIn, signUserUp } from "../misc/cognito-magic-link-methods";

export enum SignInStatus {
  MagicLinkSent,
  UserAlreadySignedIn,
  UserChallangeInvalid,
  UserConfirmedSignedIn,
  UserConfirmedChallangeInvalid,
  UserNotFound,
  UnknownError,
}

export enum SignUpStatus {
  MagicLinkSent,
  SignUpNotDone,
  UnknownError,
}

export interface SignInResponse {
  status: SignInStatus;
  error?: Error;
}

export interface SignUpResponse {
  status: SignUpStatus;
  error?: Error;
}

const DEFAULT_LENGTH = 16;
const CHARS = [
  "abcdefghijklmnopqrstuvwwyz",
  "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
  "0123456890",
  "+-^$*.[]{}()?\"!@#%&/,><':;|_~",
];

export const generatePassword = (): string => {
  let indexHistory: number[] = [];
  const gen = () => {
    let p = "";
    for (let i = 0; i < DEFAULT_LENGTH; i++) {
      const idx = Math.floor(Math.random() * 4);
      indexHistory.push(idx);

      const set = CHARS[idx];
      const l = set.length;
      const idx2 = Math.floor(Math.random() * l);
      const chr = set[idx2];
      p = p += chr;
    }
    return p;
  };

  const password = gen();
  if (
    indexHistory.includes(0) &&
    indexHistory.includes(1) &&
    indexHistory.includes(2) &&
    indexHistory.includes(3)
  ) {
    return password;
  } else {
    indexHistory = [];
    return gen();
  }
};

export const CognitoMagicLinkSignUp: React.FC = () => {
  const { hash } = useLocation();

  const { prefillValues } = useAuthNavigateState();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isSubmitting, isDirty },
  } = useForm<{
    name: string;
    email: string;
  }>({ defaultValues: prefillValues });

  const navigate = useAuthNavigate(
    () => ({
      email: getValues("email"),
      name: getValues("name"),
    }),
    [getValues],
  );
  const localNavigate = (props: {
    level: AuthMessageLevel;
    tx: TranslationFeKey;
  }) =>
    navigate({
      to: {
        type: "current_url",
      },
      state: {
        message: {
          level: props.level,
          tx: props.tx,
        },
      },
      replace: true,
    });

  useEffect(() => {
    if (hash !== "#override") {
      navigate({
        to: {
          type: "uri",
          uri: cognitoPaths.signUpDeprecated,
        },
        replace: true,
      });
    }
  }, [hash, navigate]);

  useEffect(() => {
    if (isDirty) {
      userHasInteractedWithSignupForm();
    }
  }, [isDirty]);

  const {
    i18n: { language },
  } = useTranslation();
  const { disease, organisation } = useTenantId();

  const clientMetadataMemo: CognitoClientMetadata & { [key: string]: string } =
    useMemo(
      () => ({
        disease,
        ...(organisation ? { organisation } : {}),
        isLocalhost: process.env.NODE_ENV === "development" ? "1" : "0",
        authFlowType: "CUSTOM_WITHOUT_SRP",
        signInMethod: "MAGIC_LINK",
        alreadyHaveMagicLink: "no",
        language,
      }),
      [disease, organisation, language],
    );

  const onSubmitTrySignUp = handleSubmit(({ email, name }) => {
    if (!clientMetadataMemo) {
      Sentry.captureException(
        new Error("Client metadata in sign up step is null"),
        {
          user: { email: email },
          level: "error",
        },
      );
      alert("Client metadata is not set");
      return;
    }

    return signUserIn({
      email,
      name,
      clientMetadata: clientMetadataMemo,
    }).then((result) => {
      switch (result.status) {
        case SignInStatus.MagicLinkSent:
          localNavigate({
            level: AuthMessageLevel.INFO,
            tx: "auth.signUpMagic.magicLinkLogin",
          });
          return;
        case SignInStatus.UserNotFound:
          // Note: the return is necessary so that `useForm.isSubmitting` works correctly
          return signUserUp({
            email,
            name,
            clientMetadata: clientMetadataMemo,
          }).then((signUpResult) => {
            switch (signUpResult.status) {
              case SignUpStatus.MagicLinkSent:
                navigate({
                  to: { type: "uri", uri: cognitoPaths.confirmSignUpInfo },
                  replace: true,
                });
                break;
              case SignUpStatus.SignUpNotDone:
                Sentry.captureException(result, {
                  user: { email: email },
                  level: "error",
                });
                localNavigate({
                  level: AuthMessageLevel.ERROR,
                  tx: "auth.signUpMagic.signUpNotDone",
                });
                break;
              case SignUpStatus.UnknownError:
                Sentry.captureException(result, {
                  user: { email: email },
                  level: "error",
                });
                localNavigate({
                  level: AuthMessageLevel.ERROR,
                  tx: "auth.signUpMagic.signUpUnkownError",
                });
                break;
            }
          });
        case SignInStatus.UnknownError:
          Sentry.captureException(result, {
            user: { email: email },
            level: "error",
          });
          localNavigate({
            level: AuthMessageLevel.ERROR,
            tx: "auth.signUpMagic.signInBeforeSignUpFailed",
          });
          return;
      }
    });
  });

  return (
    <Form onSubmit={onSubmitTrySignUp}>
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.name.title" }}
        placeholder={{ tx: "auth.signUp.inputs.name.placeholder" }}
        {...register("name", {
          required: tx("auth.signUp.inputs.name.fieldMissingError"),
          validate: (value) => {
            if (value.trim() === "") {
              return tx("auth.signUp.inputs.name.fieldMissingError");
            }
          },
        })}
        error={{ txUnchecked: errors.name?.message }}
      />
      <FormTextInput
        title={{ tx: "auth.signUp.inputs.email.title" }}
        placeholder={{ tx: "auth.signUp.inputs.email.placeholder" }}
        autoComplete="username"
        {...register("email", {
          required: tx("auth.signUp.inputs.email.fieldMissingError"),
          pattern: {
            value: isEmailRegex,
            message: tx("auth.signUp.inputs.email.notAnEmailError"),
          },
        })}
        error={{ txUnchecked: errors.email?.message }}
      />
      <LoadingButton
        type="submit"
        loading={isSubmitting}
        tx="auth.signUp.signUpButton"
      />
    </Form>
  );
};
