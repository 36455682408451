import { FrontendPageEnum } from "shared/model/pages";

import { StripeAdminManager } from "../components/stripe/stripe-admin-manager";
import { StripeOAuthRedirectPage } from "../components/stripe/stripe-oauth-redirect-page";
import { AdminSignup } from "../routes/admin-signup";
import { Chat } from "../routes/chat";
import { AdminDashboard, UserDashboard } from "../routes/dashboard";
import { EditNewsStory } from "../routes/edit-news-story";
import { Forum } from "../routes/forum";
import { Journey } from "../routes/journey";
import { NetworkBuilder } from "../routes/network-builder";
import { News } from "../routes/news";
import { NewsDetail } from "../routes/news-detail";
import { Notifications } from "../routes/notifications";
import { PagList } from "../routes/pag-list";
import { Report } from "../routes/report";
import { SocialMedia } from "../routes/social-media";
import { SocialMediaEdit } from "../routes/social-media-edit";
import { Subscriptions } from "../routes/subscriptions";
import { UserStory } from "../routes/user-story";
import { RouteWithTitle } from "../util/metadata-for-active-route";

export type MainRoute = Omit<RouteWithTitle, "path"> & {
  hideInNavbar?: boolean;
};

export const frontendPages: {
  [page in FrontendPageEnum]: MainRoute;
} = {
  [FrontendPageEnum.CHAT]: {
    title: { tx: "chat.headerTitle" },
    element: <Chat />,
  },
  [FrontendPageEnum.JOURNEY]: {
    title: { tx: "journey.headerTitle" },
    element: <Journey />,
  },
  [FrontendPageEnum.REPORT]: {
    title: { tx: "report.headerTitle" },
    element: <Report />,
  },
  [FrontendPageEnum.SUBSCRIPTIONS]: {
    title: { tx: "subscriptions.headerTitle" },
    element: <Subscriptions />,
  },
  [FrontendPageEnum.NEWS]: {
    title: { tx: "news.headerTitle" },
    element: <News />,
  },
  [FrontendPageEnum.NEWS_DETAIL]: {
    title: { tx: "news.headerTitleDetails" },
    hideInNavbar: true,
    element: <NewsDetail />,
  },
  [FrontendPageEnum.FORUM]: {
    title: { tx: "forum.headerTitle" },
    element: <Forum />,
  },
  [FrontendPageEnum.ADMIN_DASHBOARD]: {
    title: { tx: "membersDashboard" },
    element: <AdminDashboard />,
  },
  [FrontendPageEnum.USER_DASHBOARD]: {
    title: { tx: "dashboard" },
    element: <UserDashboard />,
  },
  [FrontendPageEnum.STRIPE]: {
    title: { tx: "stripe.stripe" },
    element: <StripeAdminManager />,
  },
  [FrontendPageEnum.STRIPE_OAUTH_REDIRECT]: {
    title: {},
    hideInNavbar: true,
    element: <StripeOAuthRedirectPage />,
  },
  [FrontendPageEnum.ADMIN_NEWS]: {
    title: { tx: "adminNews.headerTitle" },
    element: <News isAdmin />,
  },
  [FrontendPageEnum.ADMIN_NEWS_EDIT]: {
    title: { tx: "editNews.headerTitle" },
    element: <EditNewsStory />,
    hideInNavbar: true,
  },
  [FrontendPageEnum.ADMIN_SIGNUP]: {
    title: { tx: "auth.signUp.adminTitle" },
    element: <AdminSignup />,
    hideInNavbar: true,
  },
  [FrontendPageEnum.PAG_LIST]: {
    title: { tx: "pagList.headerTitle" },
    element: <PagList />,
  },
  [FrontendPageEnum.ADMIN_SOCIAL_MEDIA]: {
    title: { tx: "socialMedia.adminTitle" },
    element: <SocialMedia isAdmin />,
  },
  [FrontendPageEnum.SOCIAL_MEDIA]: {
    title: { tx: "socialMedia.headerTitle" },
    element: <SocialMedia />,
  },
  [FrontendPageEnum.ADMIN_SOCIAL_MEDIA_EDIT]: {
    title: { tx: "socialMedia.editAdminTitle" },
    element: <SocialMediaEdit />,
    hideInNavbar: true,
  },
  [FrontendPageEnum.NOTIFICATIONS]: {
    title: { tx: "notifications.title" },
    element: <Notifications />,
  },
  [FrontendPageEnum.NETWORK_BUILDER]: {
    title: { tx: "networkBuilder.title" },
    element: <NetworkBuilder />,
  },
  [FrontendPageEnum.USER_STORY]: {
    title: { tx: "userStory.title" },
    element: <UserStory />,
  },
};
